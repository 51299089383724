<template>
   <v-btn  fab small text outlined @click="changeLang()">
      {{ $i18n.locale === 'en' ? 'EN' : 'AR' }}
    </v-btn>
</template>

<script>
export default {
  methods: {
    changeLang () {
      this.$i18n.locale = this.$i18n.locale ==='en' ? 'ar' : 'en'
      localStorage.setItem('DIET_PRO_LANG',  this.$i18n.locale)
    }
  }
}
</script>

<style>

</style>